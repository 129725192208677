import {
  Key as MineIcon,
  StarBorder as NoStarIcon,
  Star as StarIcon,
} from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from '@mui/material';
import { grey, yellow } from '@mui/material/colors'; // eslint-disable-line import/extensions
import type { Event, PaginatedList, Person } from 'core';
import { DateTime } from 'luxon';
import type { FC } from 'react';
import uri from 'uri-tag';
import { CompetitionChip } from '../../components/chips/competition.js';
import { NotPublicChip } from '../../components/chips/not-public.js';
import { NotPublishedChip } from '../../components/chips/not-published.js';
import { EventSeriesEntity } from '../../components/entity/event-series.js';
import { GroupEntity } from '../../components/entity/group.js';
import {
  InfoFieldStack,
  InfoFieldView,
} from '../../components/info-field/info-field.js';
import {
  formatDate,
  formatTime,
  formatTimeDifferentDay,
} from '../../utils/format.js';

interface Props {
  data: PaginatedList<Event.WithCourses>;
  hideSeries?: boolean;
  person: Person.Type;
  showGroup: boolean;
}

export const ListItemsView: FC<Props> = ({
  data: { items, matches },
  hideSeries,
  person,
  showGroup,
}) => (
  <Stack spacing={3}>
    {!items.length ? (
      <Typography variant="body2" color="text.secondary" fontStyle="italic">
        There are no events here yet. Please check back a bit later.
      </Typography>
    ) : null}
    {items.map((item) => (
      <ButtonBase
        key={item._id}
        sx={{ justifyContent: 'stretch', textAlign: 'start' }}
        href={uri`#/event/${item._id}`}
      >
        <Card sx={{ width: '100%' }}>
          <CardContent>
            <Stack spacing={1}>
              <Box display="flex" flex={1} justifyContent="space-between">
                <Stack direction="column" spacing={0}>
                  {!hideSeries && item.eventSeriesId ? (
                    <Typography variant="body1">
                      <EventSeriesEntity
                        eventSeriesId={item.eventSeriesId}
                        link
                      />
                    </Typography>
                  ) : null}
                  <Stack direction="row" spacing={1}>
                    <Typography variant="h3">{item.title}</Typography>
                    {!item.public ? (
                      showGroup && item.groupIds ? (
                        item.groupIds.map((groupId) => (
                          <GroupEntity key={groupId} groupId={groupId} />
                        ))
                      ) : (
                        <NotPublicChip />
                      )
                    ) : undefined}
                    {item.competition ? <CompetitionChip /> : undefined}
                    {!item.published ? <NotPublishedChip /> : undefined}
                  </Stack>
                </Stack>
                <Stack spacing={1} direction="row">
                  {item.admin?.personIds?.includes(person._id) && <MineIcon />}
                  {item.starUserIds?.includes(person._id) ? (
                    <StarIcon sx={{ color: yellow[500] }} />
                  ) : (
                    <NoStarIcon sx={{ color: grey[500] }} />
                  )}
                </Stack>
              </Box>
              <InfoFieldStack>
                <InfoFieldView label="Date">
                  {formatDate(item.start) ?? '-'}
                </InfoFieldView>
                <Divider orientation="vertical" sx={{ height: '2rem' }} />
                <InfoFieldView label="Start From">
                  {formatTime(item.start) ?? '-'}
                </InfoFieldView>
                <Divider orientation="vertical" sx={{ height: '2rem' }} />
                {!DateTime.fromJSDate(item.start)
                  .toLocal()
                  .startOf('day')
                  .hasSame(
                    DateTime.fromJSDate(item.finish).toLocal(),
                    'day',
                  ) ? (
                  <InfoFieldView label="Start Util">
                    {formatTimeDifferentDay(item.finish) ?? '-'}
                  </InfoFieldView>
                ) : (
                  <InfoFieldView label="Start Util">
                    {formatTime(item.finish) ?? '-'}
                  </InfoFieldView>
                )}
                <Divider orientation="vertical" sx={{ height: '2rem' }} />
                {!DateTime.fromJSDate(item.start)
                  .toLocal()
                  .startOf('day')
                  .hasSame(DateTime.fromJSDate(item.close).toLocal(), 'day') ? (
                  <InfoFieldView label="Course Close">
                    {formatTimeDifferentDay(item.close) ?? '-'}
                  </InfoFieldView>
                ) : (
                  <InfoFieldView label="Course Close">
                    {formatTime(item.close) ?? '-'}
                  </InfoFieldView>
                )}
                {item.courses?.length ? (
                  <>
                    <Divider orientation="vertical" sx={{ height: '2rem' }} />
                    <InfoFieldView label="Courses">
                      {item.courses.length.toString()}
                    </InfoFieldView>
                  </>
                ) : null}
              </InfoFieldStack>
            </Stack>
          </CardContent>
        </Card>
      </ButtonBase>
    ))}
  </Stack>
);
